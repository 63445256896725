import { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import { DataProvider } from "../context";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { openedMixin, closedMixin, drawerWidth } from "./theme";
import { useTheme, useMediaQuery } from "@mui/material";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Sidebar({ open, handleDrawerClose }: SidebarProps) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const navigate = useNavigate();
  const location = useLocation();
  const { loading } = useContext(DataProvider);

  if (loading) return null;

  const drawer = (
    <div>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <div style={{ height: 10 }} />
      <List>
        {[
          {
            name: "Dashboard",
            Icon: DashboardIcon,
            link: "/dashboard",
            alt: "Dashboard",
          },
          {
            name: "Settings",
            Icon: SettingsIcon,
            link: "/settings",
            alt: "Settings",
          },
        ].map((menuItem, index) => (
          <ListItemButton
            key={menuItem.name}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={() => {
              if (open) {
                navigate(menuItem.link);
                window.scrollTo(0, 0);
                handleDrawerClose();
              } else {
                navigate(menuItem.link);
                window.scrollTo(0, 0);
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                color: location.pathname
                  .split("/")[1]
                  .includes(menuItem.link.split("/")[1])
                  ? theme.palette.primary.main
                  : undefined,
              }}
            >
              <Tooltip
                title={menuItem.alt}
                disableHoverListener={open}
                placement="right-end"
                componentsProps={{
                  tooltip: {
                    style: {
                      backgroundColor: "#0e0e0e",
                      outline: "1px solid rgba(255,255,255,0.8)",
                      borderRadius: 0,
                      fontFamily: "'Roboto', monospace",
                      fontSize: 12,
                      marginTop: 20,
                      opacity: 0.9,
                    },
                  },
                }}
              >
                <menuItem.Icon />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary={menuItem.name.toUpperCase()}
              sx={{
                opacity: open ? 1 : 0,
                fontFamily: "Inter",
                textTransform: "capitalize",
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </div>
  );

  return (
    <Box component="nav" aria-label="mailbox folders">
      {isSmall && (
        <MuiDrawer
          variant="temporary"
          open={open}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor: (theme) => theme.palette.background.paper,
              borderRight: "none",
              width: drawerWidth,
            },
          }}
          PaperProps={{ elevation: 3 }}
        >
          {drawer}
        </MuiDrawer>
      )}
      {isLarge && (
        <Drawer
          variant="permanent"
          open={open}
          hideBackdrop={true}
          PaperProps={{ variant: "elevation" }}
          sx={{
            "& .MuiDrawer-paper": {
              backgroundColor: "#1f1f1f",
              borderRight: "none",
            },
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
}

interface SidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

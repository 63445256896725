import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const LoFiLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  backgroundColor: "blue",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    stroke: 1,
    backgroundColor: theme.palette.grey[800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
  },
}));

export default function LinearBar({ value }: { value: number }) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LoFiLinearProgress
        variant="determinate"
        value={value}
        sx={{ "& .MuiLinearProgress-bar2Buffer": { fill: "#fff" } }}
      />
    </Box>
  );
}

/**
 * @summary Compute the period to period arithmetic returns for a given array of portfolio values
 *
 * @param portfolioEquityCurve
 * @returns {number[]}
 */
const arithmeticReturns = (portfolioEquityCurve: number[]) => {
  const returns = [...portfolioEquityCurve];
  returns[0] = NaN; // remove first element as theres no returns on day one
  for (let i = 1; i < portfolioEquityCurve.length; ++i) {
    returns[i] =
      (portfolioEquityCurve[i] - portfolioEquityCurve[i - 1]) /
      portfolioEquityCurve[i - 1];
  }

  return returns;
};

/**
 * @summary Compute the conditional value at risk (cVAR) as a percentage from an array of portfolio values at a given confidence level
 *
 * @param portfolioEquityCurve
 * @param alpha
 * @returns {number | NaN}
 */
const calculateConditionalValueAtRisk = (
  portfolioEquityCurve: number[],
  alpha: number
) => {
  const returns = arithmeticReturns(portfolioEquityCurve).slice(1); // remove first element as theres no returns on day one
  returns.sort((a: number, b: number) => a - b);
  const calpha = 1 - alpha;
  const w = Math.floor(calpha * returns.length);
  if (w === 0) return NaN; // if w is 0 the confidence is too high for the length of the dataset
  const valAtRisk = -returns[w - 1];

  return valAtRisk;
};

export { calculateConditionalValueAtRisk };

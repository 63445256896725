import { Box, Typography, Tooltip } from "@mui/material";
import LinearBar from "../charts/LinearBar";
import { calculateConditionalValueAtRisk } from "../../utils/risk";

/**
 * @description Returns the three horizontal bar charts widget
 *
 * Value 0 is volume, 1 is price and 2 is risk.
 */
const ThreeBars = ({
  values,
}: {
  values: [
    {
      // Volume
      high: number;
      low: number;
      current: number;
    },
    {
      // Price
      high: number;
      low: number;
      current: number;
    },
    number[]
  ];
}) => {
  /* Volume power is the position between the one month low/high */
  const volumePower = values[0].high
    ? (100 * values[0].current) / values[0].high
    : 0;
  const volumeChange = values[0].high
    ? (100 * values[0].current) / values[0].high
    : 100;

  /* Price power is the position between the one month low/high */
  const pricePower = values[1].high
    ? ((values[1].current - values[1].low) / (values[1].high - values[1].low)) *
      100
    : 0;
  const priceChange = values[1].high
    ? (100 * values[1].current) / values[1].high
    : 100;

  /* Calculate the cVAR with 10% being max on the chart */
  const riskValue =
    values[2].length >= 28
      ? calculateConditionalValueAtRisk(values[2], 0.95) * 100
      : 0;
  let riskPower = riskValue * 10;
  if (riskPower > 100) riskPower = 100;

  const RiskMessage = (
    <>
      <div>
        The one day Conditional Value at Risk (CVaR) is {riskValue.toFixed(1)}%
      </div>

      <div style={{ paddingTop: 8, fontSize: 10 }}>
        <b>What's this?</b> <br />
      </div>
      <div style={{ paddingTop: 2, fontSize: 10 }}>
        Conditional Value at Risk (CVaR), also known as the expected shortfall,
        is a risk assessment measure that quantifies the amount of tail risk an
        investment portfolio has.
      </div>
    </>
  );

  return (
    <>
      <Tooltip
        title={`The combined volume is down ${(100 - volumeChange).toFixed(
          1
        )}% from the one month high relative to the one month low.`}
        componentsProps={{
          tooltip: {
            style: {
              backgroundColor: "#0e0e0e",
              outline: "1px solid rgba(255,255,255,0.8)",
              borderRadius: 0,
              fontFamily: "'Roboto', monospace",
              fontSize: 12,
              marginTop: 20,
            },
          },
        }}
        TransitionProps={{ timeout: 0 }}
        followCursor
      >
        <Box sx={{ pt: 0, pb: 2.8 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography
              sx={{
                fontFamily: '"Roboto", monospace',
                fontSize: 14,
                lineHeight: 1,
                opacity: 0.9,
              }}
              variant="caption"
            >
              Volume
            </Typography>
          </Box>

          <LinearBar value={volumePower} />
        </Box>
      </Tooltip>

      <Tooltip
        title={`The portfolio value is down ${(100 - priceChange).toFixed(
          1
        )}% from its one month high.`}
        componentsProps={{
          tooltip: {
            style: {
              backgroundColor: "#0e0e0e",
              outline: "1px solid rgba(255,255,255,0.8)",
              borderRadius: 0,
              fontFamily: "'Roboto', monospace",
              fontSize: 12,
              marginTop: 20,
            },
          },
        }}
        TransitionProps={{ timeout: 0 }}
        followCursor
      >
        <Box sx={{ pb: 2.8 }}>
          <Box sx={{ mb: 0.5 }}>
            <Typography
              sx={{
                fontFamily: '"Roboto", monospace',
                fontSize: 14,
                opacity: 0.9,
              }}
              variant="caption"
            >
              Price
            </Typography>
          </Box>
          <LinearBar value={pricePower} />
        </Box>
      </Tooltip>

      <Tooltip
        title={RiskMessage}
        componentsProps={{
          tooltip: {
            style: {
              backgroundColor: "#0e0e0e",
              outline: "1px solid rgba(255,255,255,0.8)",
              borderRadius: 0,
              fontFamily: "'Roboto', monospace",
              fontSize: 12,
              marginTop: 20,
            },
          },
        }}
        TransitionProps={{ timeout: 0 }}
        followCursor
      >
        <Box>
          <Box sx={{ mb: 0.5 }}>
            <Typography
              sx={{
                fontFamily: '"Roboto", monospace',
                fontSize: 14,
                opacity: 0.9,
              }}
              variant="caption"
            >
              Risk
            </Typography>
          </Box>
          <LinearBar value={riskPower} />
        </Box>
      </Tooltip>
    </>
  );
};

export default ThreeBars;

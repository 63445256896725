import { useContext, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LineChart from '../../components/charts/LineChart'
import PieChart from '../../components/charts/PieChart'
import Gauge from '../../components/charts/Gauge'
import LEDBar from '../../components/charts/LEDBar'
import DigitalBar from '../../components/charts/DigitalBar'
import TokenList from '../../components/tables/TokenList'
import PayoutList from '../../components/tables/PayoutList'
import CardHeader from '../../components/common/CardHeader'
import ThreeBars from '../../components/widgets/ThreeBars'
import { CardContainer } from '../../components/common'
import { StatsTable } from '../../components/tables'
import { Divider, Typography } from '@mui/material'
import { DataProvider } from '../../context'
import { useTheme, useMediaQuery } from '@mui/material'
import { NotificationProvider } from '../../context'
import { logout } from '../../firebase'

const Dashboard = () => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const notification = useContext(NotificationProvider)

  const { loading, data, notFound, error } = useContext(DataProvider)

  useEffect(() => {
    if (notFound) {
      notification.set({
        message:
          "We couldn't find you. Did you use your republic.co or republiccrypto.com email address? Contact your team if the issues persists.",
        type: 'error',
      })
      setTimeout(() => {
        logout()
      }, 5)
    }
  }, [loading, notFound, error])

  if (loading) return null
  if (!data || notFound) return null

  const { potential } = data

  const tokenSalePower =
    potential.teamPower?.['Token Sale']?.length > 0
      ? potential.teamPower['Token Sale'].reduce((a: number, b: number) => a + b, 0) /
        potential.teamPower['Token Sale'].length
      : 0

  const advisoryPower =
    potential.teamPower?.['Advisory']?.length > 0
      ? potential.teamPower['Advisory'].reduce((a: number, b: number) => a + b, 0) /
        potential.teamPower['Advisory'].length
      : 0

  const tokenizationPower =
    potential.teamPower?.['Tokenization']?.length > 0
      ? potential.teamPower['Tokenization'].reduce((a: number, b: number) => a + b, 0) /
        potential.teamPower['Tokenization'].length
      : 0

  const nonZero = [tokenSalePower, advisoryPower, tokenizationPower].filter(d => d && d > 0)

  const averageTeamPower = nonZero ? nonZero.reduce((a, b) => a + b, 0) / nonZero.length : 0

  return (
    <Grid container spacing={2} alignItems='stretch' direction={'row'}>
      {/**
       * TOP ROW
       *
       * This row contains the main graph and the "statistics" card
       * */}
      <Grid item xs={12} md={7} lg={8}>
        <CardContainer>
          <CardHeader
            prefix='01'
            title='Performance'
            info='Data only includes tokens with historic prices and is for information only.'
          />
          {potential.totalEquityCurve && potential.totalEquityCurve.length > 0 ? (
            <LineChart data={potential.totalEquityCurve} />
          ) : (
            <Box
              sx={{
                minHeight: isMedium ? 220 : 320,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "'Roboto', monospace",
                    opacity: 0.9,
                  }}
                >
                  Token history unavailable
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "'Roboto', monospace",
                    opacity: 0.9,
                    pt: 0.5,
                    maxWidth: 300,
                  }}
                  variant='caption'
                >
                  If you started recently you may not be allocated any tokens yet.
                </Typography>
              </Box>
            </Box>
          )}
        </CardContainer>
      </Grid>

      <Grid item xs={12} md={5} lg={4}>
        <CardContainer>
          <CardHeader
            prefix='02'
            title='Current State'
            info='Token values are highly volatile and likely to change significantly between now and liquidation.'
          />

          <Box sx={{ pb: 1.5, pl: 1.5, pr: 1.5, pt: 1.4 }}>
            <StatsTable
              total={potential.totalValue ?? 0}
              cash={potential.totalCashValue ?? 0}
              tokens={potential.totalTokenValue ?? 0}
              change={potential.change ?? 0}
              days={potential.totalDays ?? 0}
            />
            <Box
              sx={{
                width: '100%',
                height: !isSmall ? (!isMedium ? 220 : 145) : 145,
                mt: 1.5,
              }}
            >
              <PieChart
                data={[
                  { category: 'Cash', value: potential.totalCashValue },
                  { category: 'Tokens', value: potential.totalTokenValue },
                ]}
              />
            </Box>
          </Box>
        </CardContainer>
      </Grid>

      {/**
       * SECOND ROW
       *
       * The four widgets.
       * */}
      <Grid item xs={12} sm={6} lg={3}>
        <CardContainer>
          <CardHeader info='The combined volume of all the tokens you are allocated over the last 14 days.' />
          <Box sx={{ textAlign: 'center', pl: 2, pr: 0.5, pb: 3, mt: -0.5 }}>
            <DigitalBar
              values={
                potential.totalVolume1m && potential.totalVolume1m.length >= 14
                  ? potential.totalVolume1m.slice(
                      potential.totalVolume1m.length - 14,
                      potential.totalVolume1m.length,
                    )
                  : false
              }
              name='Vol'
            />
          </Box>
        </CardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardContainer>
          <CardHeader info='The four best performing tokens in your commission portfolio.' />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              mt: -0.25,
              pb: 1.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ pb: 1.5 }}>
                <Gauge
                  value={potential.tokensByPerformance?.[0]?.power ?? 0}
                  data={potential.tokensByPerformance?.[0]}
                  symbol={potential.tokensByPerformance?.[0]?.symbol.toLowerCase() ?? ''}
                  position={1}
                />
              </Box>

              <Box sx={{ pb: 0 }}>
                <Gauge
                  value={potential.tokensByPerformance?.[2]?.power ?? 0}
                  data={potential.tokensByPerformance?.[2]}
                  symbol={potential.tokensByPerformance?.[2]?.symbol.toLowerCase() ?? ''}
                  position={3}
                />
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ pb: 1.5 }}>
                <Gauge
                  value={potential.tokensByPerformance?.[1]?.power ?? 0}
                  data={potential.tokensByPerformance?.[1]}
                  symbol={potential.tokensByPerformance?.[1]?.symbol.toLowerCase() ?? ''}
                  position={2}
                />
              </Box>

              <Box sx={{ pb: 0 }}>
                <Gauge
                  value={potential.tokensByPerformance?.[3]?.power ?? 0}
                  data={potential.tokensByPerformance?.[3]}
                  symbol={potential.tokensByPerformance?.[3]?.symbol.toLowerCase() ?? ''}
                  position={4}
                />
              </Box>
            </Box>
          </Box>
        </CardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardContainer>
          <CardHeader info='How your contracts from each business line are currently performing compared to the one month high/low.' />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              pl: 3,
              pr: 2,
              pb: 2.5,
              pt: 0.25,
            }}
          >
            <LEDBar value={tokenSalePower} name={'Token Sales'} />
            <LEDBar value={advisoryPower} name={'Advisory'} />
            <LEDBar value={tokenizationPower} name={'Tokenization'} />
            <LEDBar value={averageTeamPower || 0} name={'Average'} />
          </Box>
        </CardContainer>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <CardContainer>
          <CardHeader info='Combined stats about the current market performance of your commission portfolio vs the last month.' />

          <Box
            sx={{
              pb: 3,
              pr: 1.5,
              pl: 1.5,
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              mt: -0.5,
              height: '100%',
            }}
          >
            <ThreeBars
              values={[
                {
                  high: potential?.totalVolumeHigh,
                  low: potential?.totalVolumeLow,
                  current: potential?.totalCurrentVolume,
                },
                {
                  high: potential?.totalValueHigh,
                  low: potential?.totalValueLow,
                  current: potential?.totalValueCurrent,
                },
                potential?.totalEquityCurve?.map((e: any) => e.value) ?? [],
              ]}
            />
          </Box>
        </CardContainer>
      </Grid>

      {/**
       * THIRD ROW
       *
       * This row contains the token list
       * */}
      <Grid item xs={12} md={9}>
        <CardContainer>
          <CardHeader
            prefix='03'
            title='Tokens'
            info='These values are likely to change significantly and may not include all the tokens you are allocated.'
          />

          <Box sx={{ mr: -1.5, ml: -1.5, mb: -1.5, mt: 1.5 }}>
            <TokenList />
          </Box>
        </CardContainer>
      </Grid>
      {/** Payments comments out as they're not currently in sync with the latest data  **/}

      {/* <Grid item xs={12} md={3}>
        <CardContainer>
          <CardHeader
            prefix='04'
            title='Payouts'
            info='Payouts are not scheduled for distribution until they have been approved. Values are liable to change at any time, even after approval.'
          />

          <Box sx={{ mr: -1.5, ml: -1.5, mb: -1.5, mt: 1.5 }}>
            <PayoutList />
          </Box>
        </CardContainer>
      </Grid> */}
    </Grid>
  )
}

export default Dashboard

import { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const PieChart = ({
  data,
}: {
  data: { category: string; value: number }[];
}) => {
  const rootRef = useRef<am5.Root | null>(null);
  useLayoutEffect(() => {
    if (rootRef.current !== null) return;

    rootRef.current = am5.Root.new("piediv");
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    const myTheme = am5themes_Animated.new(rootRef.current);
    myTheme
      .rule("Label")
      .setAll({ fontFamily: "'Roboto', monospace", fill: am5.color("#fff") });
    rootRef.current.setThemes([myTheme]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    var chart = rootRef.current.container.children.push(
      am5percent.PieChart.new(rootRef.current, {
        endAngle: 270,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var series = chart.series.push(
      am5percent.PieSeries.new(rootRef.current, {
        valueField: "value",
        categoryField: "category",
        endAngle: 270,
        // fill: am5.color("#ffffff"),
      })
    );

    series.ticks.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    series.labels.template.setAll({
      text: "{category}\n[opacity:0.8;]{valuePercentTotal.formatNumber('0.00')}%[/]",
      // textType: "radial",
      radius: 25,
      opacity: 0.8,
      fontSize: 14,
    });

    series.states.create("hidden", {
      endAngle: -90,
    });

    series.slices.template.setAll({
      fillOpacity: 0,
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
    });

    series.slices.template.states.create("active", {
      shiftRadius: 10,
      stroke: am5.color("rgb(72, 255, 207)"),
      strokeWidth: 1,
    });

    series.slices.template.set("tooltipText", "");

    series
      .get("colors")
      ?.set("colors", [am5.color("#121212"), am5.color("#121212")]);

    let count = 0;
    series.slices.events.on("push", (evt) => {
      if (count === 1) {
        setTimeout(() => {
          evt.newValue.states.applyAnimate("active");
        }, 1500);
      } else {
        count++;
      }
    });

    /* Sort the data so the smaller value is on the left and highlighted for aesthetics */
    data.sort((a, b) => (a.value > b.value ? -1 : 1));
    series.data.setAll(data.filter((d) => d.value));

    series.appear(1000, 100);
  }, []);

  return (
    <>
      <div id="piediv" style={{ width: "100%", height: "100%" }}></div>
    </>
  );
};

export default PieChart;

import Title from "./Title";
import Info from "./Info";
import { Box } from "@mui/material";

const CardHeader = ({ prefix, title, info }: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {prefix && title ? <Title prefix={prefix} title={title} /> : null}
      <Box sx={{ ml: "auto", lineHeight: 1 }}>
        <Info text={info} />
      </Box>
    </Box>
  );
};

interface Props {
  prefix?: string;
  title?: string;
  info: string;
}

export default CardHeader;

import React, { useContext } from "react";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CryptoTextLogo from "./CryptoText";
import Logo from "./Logo";
import { AuthProvider, DataProvider } from "../context";
import { drawerWidth } from "./theme";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  ListItemIcon,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Logout } from "@mui/icons-material";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar({
  open,
  name,
  handleDrawerOpen,
  logout,
}: TopBarProps) {
  const navigate = useNavigate();
  const { loading } = useContext(DataProvider);
  const userContext = useContext(AuthProvider);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  if (loading)
    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="glitch-container"
              style={{ height: 55, width: 200 }}
            >
              <CryptoTextLogo
                fill="#fff"
                style={{
                  height: 55,
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                }}
                className="glitch"
              />
              <CryptoTextLogo
                fill="red"
                style={{
                  height: 55,
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                }}
                className="glitch"
              />
              <CryptoTextLogo
                fill="blue"
                style={{ height: 55, position: "absolute" }}
                className="glitch"
              />
            </div>
          </Box>
        </div>
      </div>
    );

  return (
    <AppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: isSmall ? 2 : 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} style={{ paddingTop: 6 }}>
          <Logo
            onClick={() => {
              window.scrollTo(0, 0);
              navigate("/dashboard");
            }}
            style={{ cursor: "pointer", strokeDasharray: 1000 }}
          />
        </Box>
        <UserMenu
          avatar={
            userContext.user && userContext.user.photoURL
              ? userContext.user.photoURL
              : ""
          }
          name={name}
          logout={logout}
        />
      </Toolbar>
    </AppBar>
  );
}

function UserMenu({
  avatar,
  name,
  logout,
}: {
  avatar: string;
  name: string;
  logout: () => void;
}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip
          title="Actions"
          componentsProps={{
            tooltip: {
              style: {
                backgroundColor: "#0e0e0e",
                outline: "1px solid rgba(255,255,255,0.8)",
                borderRadius: 0,
                fontFamily: "'Roboto', monospace",
                fontSize: 12,
                marginTop: 20,
                opacity: 0.9,
              },
            },
          }}
        >
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, textTransform: "none", color: "inherit" }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 38, height: 38 }} src={avatar} />
            {/* <GlitchImage img={avatar} height={38} width={38} /> */}
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 0.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface TopBarProps {
  open: boolean;
  avatar: string;
  name: string;
  handleDrawerOpen: () => void;
  logout: () => void;
}

import { Box, Typography } from "@mui/material";

const UserNotFound = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <img src="/images/note.svg" style={{ height: 100 }} />
          </Box>

          <Box sx={{ pb: 1, mt: -1 }}>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "'Roboto', monospace",
                fontSize: 18,
              }}
            >
              User Not Found
            </Typography>
          </Box>

          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "'Roboto', monospace",
                fontSize: 14,
              }}
            >
              We couldn't find you in the system.
              <br />
              Contact your manager if something should be here.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UserNotFound;

import { createContext, useContext, useState, useEffect, useCallback } from 'react'
import { AuthProvider } from './AuthContext'
import { NotificationProvider } from './NotificationContext'
import { processPotentialSplits } from '../utils'

const initialState = {
  data: [],
  loading: true,
  notFound: false,
  error: false,
}

export const DataProvider = createContext<DataContextProps>(initialState)

export const DataContext = ({
  children,
  userCheck,
}: {
  children: JSX.Element | JSX.Element[]
  userCheck?: string
}) => {
  const [values, setValues] = useState<DataContextProps>(initialState)
  const auth = useContext(AuthProvider)
  const notifications = useContext(NotificationProvider)

  const fetchData = useCallback(async () => {
    /* Generate the token to use when fetching data */
    const token = await auth.getToken()
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    /* Stop if the user doesn't have their email set (whether its republic or not done elsewhere) */
    if (!auth?.user?.email) {
      setValues({ data: null, loading: false, notFound: true, error: false })
      return
    }

    /* Set the values of the date range for the time series data */
    const today = new Date()
    const onMonthAgo = new Date()
    onMonthAgo.setMonth(today.getMonth() - 1)

    /* Fetch all the sources we have so the page doesn't have to load twice */
    const symbolsQuery = coinInfo.map((symbol: string) => `symbols=${symbol}`).join('&')

    /* Create a list of urls to fetch so we can do them async */
    const toFetch = [
      `https://sonar-api.republic-beta.app/commission?email=${userCheck || auth.user.email}`,
      'https://radar-proxy.republic-beta.app/symbolsnapshot',
      `https://radar-proxy.republic-beta.app/ohlc?${symbolsQuery}&start=${onMonthAgo
        .toISOString()
        .substring(0, 10)}&end=${today.toISOString().substring(0, 10)}&interval=daily`,
    ]

    try {
      /* Fetch the data from all endpoints */
      const [userData, symbolSnapshot, marketData] = await Promise.all(
        toFetch.map(async (url: string) => {
          return fetch(url, options)
            .then(async res => {
              if (res.ok) {
                const json = await res.json()
                return json
              } else return false
            })
            .catch(_err => {
              console.error('Failed to fetch user data')
              return false
            })
        }),
      )

      /* Return if user not found/no data  */
      if (!userData) {
        setValues({ data: null, loading: false, notFound: true, error: false })
        return
      }

      /* Set error true and return if any endpoint failed */
      if (userData.statusCode || symbolSnapshot.statusCode || marketData.statusCode) {
        notifications.set({
          message: `Failed to fetch: ${userData.statusCode || userData.statusCode}`,
          type: 'error',
        })
        setValues({ data: null, loading: false, notFound: false, error: true })
        return
      }

      /* Format commission rules for one table */
      const commissionRules: any = []
      userData[0].models &&
        userData[0].models.forEach((m: any) => {
          commissionRules.push({
            name: m.name,
            type: 'Pools',
            target: `All Contracts`,
            startDate: m.startDate ? new Date(m.startDate) : undefined,
            endDate: m.endDate ? new Date(m.endDate) : undefined,
            percentage: m.percentage,
          })
        })

      /* Sort the commission model/role rules alphabetically for display */
      commissionRules.sort((a: any, b: any) => a.name.localeCompare(b.name))

      /* Combine commission sources in to uniform rows for display in a table */
      userData[0].roles &&
        userData[0].roles.forEach((r: any) => {
          commissionRules.push({
            name: r.roleName,
            type: 'Role',
            target: r.contractName.replace(/ - /g, '-'),
            startDate: r.startDate ? new Date(r.startDate) : undefined,
            endDate: r.endDate ? new Date(r.endDate) : undefined,
            percentage: r.percentage,
          })
        })

      userData[0].individual &&
        userData[0].individual.forEach((i: any) => {
          commissionRules.push({
            name: i.modelName,
            type: 'Individual',
            target: `${i.modelName.split('-')[1]} contracts`,
            startDate: i.startDate ? new Date(i.startDate) : undefined,
            endDate: i.endDate ? new Date(i.endDate) : undefined,
            percentage: i.percentage,
          })
        })

      userData[0].discretionary &&
        userData[0].discretionary.forEach((d: any) => {
          commissionRules.push({
            name: d.name,
            type: 'Discretionary',
            target: d.models.join(', '),
            startDate: undefined,
            endDate: undefined,
            percentage: d.percentage,
          })
        })

      userData[0].overrides &&
        userData[0].overrides.forEach((o: any) => {
          commissionRules.push({
            name: o.name,
            type: 'Discretionary',
            target: o.contractName.replace(/ - /g, '-'),
            startDate: undefined,
            endDate: undefined,
            percentage: o.percentage,
          })
        })

      const payouts: any = {}

      /* Format liquidation splits in to uniform rows of approve/unapproved payouts */
      userData[0].liquidationSplits &&
        userData[0].liquidationSplits.forEach((s: any) => {
          if (s.approved) {
            const key = s.approved.substring(0, 10)
            if (payouts[key]) {
              payouts[key].splits.push(s)
              payouts[key].value += s.value
            } else {
              payouts[key] = {
                approved: new Date(key),
                value: s.value,
                splits: [s],
              }
            }
          } else {
            if (payouts.awaitingApproval) {
              payouts.awaitingApproval.splits.push(s)
              payouts.awaitingApproval.value += s.value
            } else {
              payouts.awaitingApproval = {
                approved: undefined,
                value: s.value,
                splits: [s],
              }
            }
          }
        })

      /* Process cash/tokens that have not reached maturation */
      const potential = processPotentialSplits(
        userData[0].potentialSplits,
        symbolSnapshot,
        marketData.data,
      )

      /* Set the values to pass to the  */
      setValues({
        data: {
          ...userData[0],
          commissionRules,
          potential,
          payouts: Object.values(payouts),
        },
        loading: false,
        notFound: false,
        error: false,
      })

      notifications.set({
        message:
          'The data presented is illustrative only and does not constitute any guarantee or promise of payment or any right to any payment or any obligation to you by Republic.',
        type: 'error',
      })
    } catch (e: any) {
      /* Set error true if anything failed and notify the user */
      setValues({ data: null, loading: false, notFound: false, error: true })
      notifications.set({
        message: `Network error: ${e.message ?? e.status ?? e}`,
        type: 'error',
      })
      console.log(e)
    }
  }, [auth])

  useEffect(() => {
    if (
      auth.user &&
      auth.user !== null &&
      (auth?.user?.email?.split('@')[1] === 'republic.co' ||
        auth?.user?.email?.split('@')[1] === 'republiccrypto.com')
    ) {
      fetchData()
    } else {
      /* Clear data if the user is logged out */
      setValues({ data: null, loading: false, notFound: false, error: false })
    }
  }, [auth])

  return <DataProvider.Provider value={values}>{children}</DataProvider.Provider>
}

interface DataContextProps {
  data: any
  loading: boolean
  notFound: boolean
  error: boolean
}

/* Tokens that have an icon (will attempt to fetch market data from server ) */
export const coinInfo: string[] = [
  "AGT",
  "AGI",
  "ALGO",
  "ARCC",
  "ARCD",
  "ASTRA",
  "ATLAS",
  "AVAX",
  "AXS",
  "BNT",
  "BSP",
  "CBSN",
  "CEEK",
  "CERE",
  "CRAFT",
  "DES",
  "DFT",
  "DRX",
  "DODO",
  "DOGE",
  "DPR",
  "DRIP",
  "ECHO",
  "EGLD",
  "ENTRP",
  "EPIX",
  "EQ",
  "EST",
  "ETH",
  "EVERY",
  "FCD",
  "FIO",
  "FLOW",
  "FRESH",
  "GARI",
  "GDT",
  "GG",
  "GPS",
  "HIGH",
  "HIKE",
  "HMND",
  "HNT",
  "INK",
  "JET",
  "KOI",
  "KBT",
  "LAND",
  "LINK",
  "LRC",
  "MATIC",
  "MCT",
  "MINA",
  "MMG",
  "MOC",
  "MOJ",
  "MRAY",
  "MNRY",
  "MWM",
  "NEAR",
  "NODL",
  "NST",
  "OP",
  "OPG",
  "OPEN",
  "ORE",
  "OST",
  "PEG",
  "PNTM",
  "PODS",
  "POKT",
  "POLIS",
  "PONT",
  "PORTAL",
  "POW",
  "PUSD",
  "PXL",
  "PYTH",
  "RADAR",
  "RARI",
  "RATIO",
  "REACH",
  "REGEN",
  "RGU",
  "RTFKT",
  "SAND",
  "SBR",
  "SCRT",
  "SOL",
  "STATE",
  "SUPRA",
  "SWITCH",
  "SWNG",
  "UFIT",
  "UPRET",
  "USDC",
  "VET",
  "VGX",
  "VYGVF",
  "WTHR",
  "XAR",
  "XLM",
  "XTZ",
  "ZBC",
  "ZED",
  "ZKL",
];

import { useState, useContext, useRef, useEffect } from 'react'
import { NotificationProvider } from '../../context'
import { login } from '../../firebase'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Info } from '../../components/common'
import CryptoTextLogo from '../../layout/CryptoText'

const Login = () => {
  const [loading, setLoading] = useState(false)
  const [termsRead, setTermsRead] = useState(false)
  const notifications = useContext(NotificationProvider)

  const listInnerRef = useRef<HTMLElement>()

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      const isNearBottom = scrollTop + clientHeight >= scrollHeight

      if (isNearBottom) {
        setTermsRead(true)
      }
    }
  }

  useEffect(() => {
    const listInnerElement = listInnerRef.current

    if (listInnerElement) {
      listInnerElement.addEventListener('scroll', onScroll)

      // Clean-up
      return () => {
        listInnerElement.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Card
          style={{
            minWidth: 330,
            maxWidth: 420,
            borderRadius: 10,
            padding: 16,
            opacity: 0.99,
          }}
          elevation={2}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mt: -0.5,
                pb: 2.75,
              }}
            >
              <div className='glitch-container' style={{ height: 35, width: 200 }}>
                <CryptoTextLogo
                  fill='#fff'
                  style={{
                    height: 35,
                    position: 'absolute',
                  }}
                  className='glitch'
                />
                <CryptoTextLogo
                  fill='red'
                  style={{
                    height: 35,
                    position: 'absolute',
                    transform: 'translate(-50%, -50%)',
                  }}
                  className='glitch'
                />
                <CryptoTextLogo
                  fill='blue'
                  style={{ height: 35, position: 'absolute' }}
                  className='glitch'
                />
              </div>
              <Box sx={{ ml: 'auto' }}>
                <Info text='If you find any issues please contact your team leader.' />
              </Box>
            </Box>
            <Box
              sx={{
                border: '1px dashed rgba(66, 66, 66, 0.2)',
                borderRadius: 1,
                mb: 2.25,
                ml: -0.3,
                mr: -0.3,
                pt: 0.5,
              }}
              id='corners-border'
            >
              <Box
                sx={{
                  width: '100%',
                  height: 300,
                  p: 2,
                  pt: 0.75,

                  overflow: 'scroll',
                  maskImage: 'linear-gradient(to bottom, black calc(100% - 40%), transparent 120%)',
                }}
                ref={listInnerRef}
              >
                <Typography
                  sx={{
                    fontFamily: "'Roboto', monospace",
                    opacity: 1,
                    color: 'white',
                  }}
                  variant='caption'
                >
                  The commission structure outlined herein is provided for informational purposes
                  only and is subject to change without prior notice. The commission percentages are
                  not fixed and may vary based on various factors, including but not limited to
                  market conditions, token lockup periods, and the discretion of the leadership
                  team. The commission percentages mentioned do not constitute promises, whether
                  implied or express, and are not binding on Republic or any affiliated entities.
                  Republic retains the right to modify, suspend, or terminate the commission program
                  at any time, at its sole discretion, without assuming any liability. Commissions
                  distributed through this structure may be limited by token lockups, market
                  conditions that could restrict the ability to sell tokens, and the preference to
                  retain tokens for staking or other yield-earning purposes. Republic is not
                  obligated to liquidate tokens to fulfill commission payments. Furthermore, the
                  eligibility to participate in the commission program and receive commissions is
                  contingent upon an individual's active employment with Republic. If an employee's
                  employment is terminated before a commission payment is due, they shall have no
                  claim or entitlement to such commission. Any information regarding commissions
                  displayed on a commission dashboard is solely for illustrative purposes and should
                  not be construed as a promise, either express or implied, of actual commission
                  earnings. By participating in the commission program, employees and affiliates
                  acknowledge and agree that the commission percentages and associated terms are
                  subject to change and are not legally enforceable commitments by Republic. This
                  disclaimer is incorporated into and forms an integral part of any agreement or
                  arrangement related to the commission structure. All parties involved are advised
                  to seek independent legal counsel regarding the commission program and its
                  implications.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 2.5, mt: 3, textAlign: 'center' }}>
              <Typography sx={{ fontFamily: "'Roboto', monospace" }} variant='body2'>
                {!termsRead ? 'Please read the terms above.' : 'Thank you!'}
              </Typography>
            </Box>

            <Box sx={{ mb: 2, textAlign: 'center' }}></Box>
            <div style={{ textAlign: 'center', paddingBottom: 2 }}>
              <LoadingButton
                onClick={() => login(setLoading, notifications)}
                loading={loading}
                variant='outlined'
                color='primary'
                sx={{ borderRadius: 10, maxWidth: 300, color: 'white' }}
                disabled={!termsRead}
                fullWidth
              >
                Agree and Proceed
              </LoadingButton>
              <Box sx={{ mt: 1, mb: 0, textAlign: 'center' }}>
                <Typography
                  sx={{ fontFamily: "'Roboto', monospace" }}
                  variant='caption'
                  color='primary'
                >
                  <span style={{ color: 'white' }}>Use your </span>
                  @republic.co&nbsp;
                  <span style={{ color: 'white' }}>or </span>
                  @republiccrypto.com&nbsp;
                  <span style={{ color: 'white' }}>address.</span>
                </Typography>
              </Box>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default Login

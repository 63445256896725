import { Typography, useTheme, Tooltip } from "@mui/material";
import { Box } from "@mui/material";

const LEDBar = ({ value, name }: { value: number; name: string }) => {
  const theme = useTheme();
  const offColor = theme.palette.grey[800];
  const onColor = theme.palette.primary.main;

  let lights: number =
    value !== 0 ? 6 - Math.round((100 - value) / 16.66666666666) : 0;

  const iterator = new Array(6).fill(null);

  return (
    <Tooltip
      title={
        value !== 0
          ? `On average your ${
              name !== "Average" ? name : ""
            } projects are down ${(100 - value).toFixed(
              1
            )}% from their one month high relative to the one month low.`
          : `You're not receiving any tokens ${
              name !== "Average" ? "from " + name : ""
            } with market data available, yet.`
      }
      componentsProps={{
        tooltip: {
          style: {
            backgroundColor: "#0e0e0e",
            outline: "1px solid rgba(255,255,255,0.8)",
            borderRadius: 0,
            fontFamily: "'Roboto', monospace",
            fontSize: 12,
            marginTop: 20,
          },
        },
      }}
      TransitionProps={{ timeout: 0 }}
      followCursor
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{ display: "flex", flexDirection: "column" }}
          className="dim-on-hover"
        >
          {iterator
            .map((_, i) => (
              <Box
                style={{
                  height: 18,
                  width: 18,
                  marginBottom: i > 0 ? 6 : 0,
                }}
                className="dim-on-hover"
                key={`led-name-${i}`}
              >
                <svg
                  viewBox="0 0 100 100"
                  baseProfile="full"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="100%"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="45"
                    fill={i < lights ? onColor : offColor}
                    opacity={i < lights ? 1 : 0.7}
                    strokeWidth={5}
                  />
                </svg>
              </Box>
            ))
            .reverse()}
        </Box>
        <Typography
          sx={{
            textOrientation: "sideways-left",
            writingMode: "vertical-lr",
            transform: "rotate(180deg)",
            fontFamily: "'Roboto', monospace",
            fontSize: 16,
            textAlign: "left",
            pt: 0,
            pr: 0.25,
            opacity: value === 0 ? 0.2 : 0.9,
          }}
        >
          {name}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default LEDBar;

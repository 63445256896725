import * as React from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { DataProvider } from "../../context";
import { Stack } from "@mui/material";

const StyledGrid = styled(DataGridPro)(({ theme }) => ({
  border: "none",
  fontFamily: "'Roboto', monospace",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 367,
  width: "100%",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

export default function CommissionList() {
  const context = React.useContext(DataProvider);
  const { commissionRules } = context.data;
  const rows = commissionRules.map((r: any, i: number) => {
    return {
      id: i,
      ...r,
    };
  });

  const DataGridComponent = StyledGrid;

  return (
    <StyledBox>
      <DataGridComponent
        columns={cols}
        rows={rows}
        disableSelectionOnClick
        rowThreshold={0}
        initialState={{
          pinnedColumns: { left: ["name"] },
          sorting: { sortModel: [{ field: "projectName", sort: "asc" }] },
        }}
        pagination={false}
        autoPageSize={false}
        pageSize={undefined}
        components={{
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Nothing yet!
            </Stack>
          ),
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              Nothing yet!
            </Stack>
          ),
        }}
      />
    </StyledBox>
  );
}

const cols: GridColumns = [
  { field: "name", headerName: "Rule", width: 180 },
  { field: "type", headerName: "Type", width: 110 },
  { field: "target", headerName: "Target", width: 210 },
  { field: "startDate", headerName: "Start Date", width: 130, type: "date" },
  { field: "endDate", headerName: "End Date", width: 130, type: "date" },
  {
    field: "percentage",
    headerName: "Percent",
    width: 100,
    valueFormatter: ({ value }) => (value ? value + " %" : 0 + " %"),
  },
];

import { useContext, useEffect } from "react";
import { NotificationProvider } from "../context";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const notification = useContext(NotificationProvider);
  const navigate = useNavigate();

  useEffect(() => {
    notification.set({ message: "Page not found", type: "error" });
    setTimeout(() => {
      navigate("/dashboard");
    }, 1000);
  }, []);

  return null;
};

export default NotFound;

import { Typography, useTheme, Tooltip, Fade } from "@mui/material";
import { Box } from "@mui/material";
import { currency } from "../../utils/format";

const DigitalBar = ({
  values,
  name,
}: {
  values: { date: Date; volume: number }[];
  name: string;
}) => {
  const theme = useTheme();

  /* Set the dimensions */
  const height = new Array(10).fill(null);
  const width = new Array(14).fill(null);

  /* Set the colors for on and off */
  const onColor = theme.palette.primary.main;
  const offColor = theme.palette.grey[800];

  /* Remove scale the axis and calculate the ratio to make it 1-10  */
  const min = values ? Math.min(...values.map((v) => v.volume)) : 0;
  const scaled = values ? values.map((v) => v.volume - min) : [];
  const ratio = values ? Math.max.apply(Math, scaled) / 10 : 1;

  /* Calculate the lights to turn on */
  const lights = scaled.map((volume) => {
    const normalized = Math.round(volume) / ratio;
    return normalized > 1 ? normalized : 1;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
      className="dim-on-hover"
    >
      <>
        {width.map((_, i) => (
          <Tooltip
            title={`${
              values?.[i]?.date.toISOString().substring(0, 10) ?? ""
            }\nVolume: ${
              values ? currency(values[i].volume) : "No enough data"
            } `}
            sx={{ width: "100%", p: 10 }}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 0 }}
            componentsProps={{
              tooltip: {
                style: {
                  backgroundColor: "#0e0e0e",
                  outline: "1px solid rgba(255,255,255,0.8)",
                  borderRadius: 0,
                  fontFamily: "'Roboto', monospace",
                  fontSize: 12,
                  marginTop: 20,
                },
              },
            }}
            followCursor
            key={`digital-${i}`}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              className="dim-on-hover"
              key={`digital-${i}`}
            >
              {height
                .map((_, j) => (
                  <Box
                    style={{
                      height: 9,
                      width: 9,
                      marginBottom: 5,
                    }}
                    key={`digital-${i}-${j}`}
                  >
                    <svg
                      viewBox="0 0 100 100"
                      baseProfile="full"
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                    >
                      <rect
                        width="100%"
                        height="100%"
                        fill={j < lights[i] ? onColor : offColor}
                        opacity={j < lights[i] ? 1 : 0.7}
                        strokeWidth={5}
                      />
                    </svg>
                  </Box>
                ))
                .reverse()}
            </Box>
          </Tooltip>
        ))}
      </>
      <Typography
        sx={{
          textOrientation: "sideways-left",
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          fontFamily: "'Roboto', monospace",
          fontSize: 16,
          textAlign: "left",
          lineHeight: 1,
          pt: -0.5,
          pr: -0.5,
          opacity: 0.9,
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default DigitalBar;

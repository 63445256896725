import * as React from "react";
import { NotificationProvider, Notification } from "../../context";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function NotificationPopUp() {
  const [open, setOpen] = React.useState(false);
  const [active, setActive] = React.useState<Notification>();

  const context = React.useContext(NotificationProvider);
  let timer: NodeJS.Timeout;

  React.useEffect(() => {
    if (context.notifications.length > 0) {
      if (context.notifications[0].message !== active?.message) {
        timer && clearTimeout(timer);
        setActive(context.notifications[0]);
        context.clear(context.notifications[0].id);
        setOpen(true);
        timer = setTimeout(() => {
          setOpen(false);
        }, 10000);
      } else {
        context.clear(context.notifications[0].id);
      }
    }
  }, [context]);

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setActive(undefined);
      }, 100);
    }
  }, [open]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={30000}
        onClose={handleClose}
        action={action}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity={active?.type ?? "info"}>
          {active?.message ?? "Something happened"}
        </Alert>
      </Snackbar>
    </div>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import TopBar from "./AppBar";
import Sidebar from "./Sidebar";
import NotificationPopUp from "../components/notifications";
import { useTheme, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext, DataContext, NotificationContext } from "../context";
import { Outlet, useParams } from "react-router-dom";
import { User } from "firebase/auth";
import { auth, logout } from "../firebase";

const Layout = () => {
  const [currentUser, setCurrentUser] = React.useState<User | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const { userCheck } = useParams();

  const checkUserRef = React.useRef(userCheck);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (!!!user) {
        setCurrentUser(null);
      } else {
        setCurrentUser(user);
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  React.useEffect(() => {
    /* If not logged in redirect all paths to login */
    if (currentUser === null && location.pathname !== "/login")
      navigate("/login");

    /* If logged in redirect login and root to the dashboard */
    if (
      !!currentUser &&
      (location.pathname === "/login" || location.pathname === "/")
    )
      navigate("/dashboard");
  }, [location, currentUser]);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <NotificationContext>
          <NotificationPopUp />
          <AuthContext>
            {!!currentUser ? (
              <>
                <DataContext userCheck={checkUserRef?.current}>
                  <TopBar
                    open={open}
                    name={currentUser?.displayName || ""}
                    avatar={currentUser?.photoURL || ""}
                    handleDrawerOpen={handleDrawerOpen}
                    logout={() => logout()}
                  />
                  <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      p: isMobile ? 1 : 3,
                      pt: isMobile ? 2 : 3,
                      opacity: 0.96,
                    }}
                  >
                    <DrawerHeader />
                    <Box
                      style={{
                        height: "calc(100% - 40px)",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          maxWidth: 2000,
                          m: "auto",
                        }}
                      >
                        <Outlet />
                      </Box>
                    </Box>
                  </Box>
                </DataContext>
              </>
            ) : (
              <Outlet />
            )}
          </AuthContext>
        </NotificationContext>
      </Box>
    </>
  );
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default Layout;

import * as React from 'react'
import Box from '@mui/material/Box'
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro'
import { styled } from '@mui/material/styles'
import { DataProvider } from '../../context'
import { Chip, Stack } from '@mui/material'
import { currency, number, capitalize } from '../../utils/format'
import { coinInfo } from '../../context/DataContext'

const StyledGrid = styled(DataGridPro)(({ theme }) => ({
  border: 'none',
  fontFamily: "'Roboto', monospace",
}))

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 576,
  width: '100%',
  '& .MuiFormGroup-options': {
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    '& > div': {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}))

export default function TokenList() {
  const context = React.useContext(DataProvider)
  const { potential } = context.data
  const rows =
    potential?.tokenSplits?.map((s: any, i: number) => {
      return {
        id: i,
        icon: coinInfo.includes(s.symbol)
          ? `/images/${s.symbol.toLowerCase()}.svg`
          : `/images/note.svg`,
        symbol: s.symbol,
        projectName: s.projectName,
        type: capitalize(s.commissionType),
        source: s.roleName || s.poolName || s.modelName,
        quantity: s.quantity,
        value: s.value,
        price: s.merged && s.value
          ? currency(s.Quote)
          : s.value && s.quantity
          ? currency(s.value / s.quantity)
          : "--",
        percentage: s.merged  && s.PercentChange ? s.PercentChange?.toFixed(2) + "%" : "--",
      };
    }) ?? [];

  const DataGridComponent = StyledGrid

  return (
    <StyledBox>
      <DataGridComponent
        columns={cols}
        rows={rows}
        disableSelectionOnClick
        rowThreshold={0}
        initialState={{
          pinnedColumns: { left: ['icon', 'projectName'] },
          sorting: { sortModel: [{ field: 'value', sort: 'desc' }] },
        }}
        pagination={false}
        autoPageSize={false}
        pageSize={undefined}
        components={{
          NoResultsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              Nothing tokens yet!
            </Stack>
          ),
          NoRowsOverlay: () => (
            <Stack height='100%' alignItems='center' justifyContent='center'>
              Nothing tokens yet!
            </Stack>
          ),
        }}
      />
    </StyledBox>
  )
}

const cols: GridColumns = [
  {
    field: 'icon',
    headerName: '',
    width: 40,
    renderCell: ({ value }) => (
      <img src={value} className='circle' style={{ height: 30, width: 30 }} alt='' />
    ),
  },
  { field: 'symbol', headerName: 'Symbol', width: 100 },
  { field: 'projectName', headerName: 'Project', width: 150 },
  {
    field: 'quantity',
    headerName: 'Allocation',
    width: 130,
    valueFormatter: ({ value }) => (value ? number(value, 3) : 'Pending'),
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 100,
    renderCell: ({ value }) => (
      <Chip variant='outlined' size='small' label={value} sx={{ color: 'white' }} />
    ),
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 150,
    renderCell: ({ value }) => (
      <Chip variant='outlined' size='small' color='primary' label={value} />
    ),
  },
  {
    field: 'value',
    headerName: 'Value',
    width: 130,
    valueFormatter: ({ value }) => (value ? currency(value) : 'Pending'),
  },
  {
    field: 'price',
    headerName: 'Price',
    width: 100,
  },
  { field: 'percentage', headerName: '24h Change', width: 100 },
]

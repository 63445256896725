export const currency = (value: number, decimals?: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: decimals ? decimals : 2,
  }).format(Number(value));

export const number = (value: number, decimals: number) =>
  new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(Number(value));

export function capitalize(s: string) {
  return s && s[0].toUpperCase() + s.slice(1);
}

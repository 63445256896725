import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./layout";

import CssBaseline from "@mui/material/CssBaseline";

import GlobalStyles from "@mui/material/GlobalStyles";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "./layout";

import { Login } from "./views/login";
import { Dashboard } from "./views/dashboard";
import { Settings } from "./views/settings";
import UserNotFound from "./layout/UserNotFound";
import NotFound from "./layout/404";
import * as am5 from "@amcharts/amcharts5";

import { LicenseInfo } from "@mui/x-license-pro";

am5.addLicense("AM5C314272773");
LicenseInfo.setLicenseKey(
  "8f625828c96fc20cda3c503c305964c4T1JERVI6NDMyMjIsRVhQSVJZPTE2ODM0NDg4MzAwMDAsS0VZVkVSU0lPTj0x"
);

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ input: { colorScheme: "dark" } }} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard/:userCheck" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/no-user-data" element={<UserNotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

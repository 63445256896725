import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const Title = ({ prefix, title }: Props) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row", pb: 2 }}>
      <Typography
        sx={{
          fontFamily: '"Roboto", monospace',
          marginRight: 1,
          lineHeight: 1,
        }}
        color="primary"
      >
        {prefix}/
      </Typography>
      <Typography
        sx={{ fontFamily: "Inter", wordWrap: "break-word", lineHeight: 1 }}
        variant="h5"
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};

interface Props {
  prefix: string;
  title: string;
}

export default Title;

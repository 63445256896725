import * as React from "react";
import Box from "@mui/material/Box";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { styled } from "@mui/material/styles";
import { Chip, Stack } from "@mui/material";
import { capitalize, number } from "../../utils/format";
import { DataProvider } from "../../context";

let keyCount = 0;
const getKey = () => {
  const key = keyCount;
  keyCount++;
  return key;
};

const StyledGrid = styled(DataGridPro)(({ theme }) => ({
  border: "none",
  fontFamily: "'Roboto', monospace",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: 576,
  width: "100%",
  "& .MuiFormGroup-options": {
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    "& > div": {
      minWidth: 100,
      margin: theme.spacing(2),
      marginLeft: 0,
    },
  },
}));

export default function ContractList() {
  const context = React.useContext(DataProvider);
  const { potentialSplits } = context.data;

  const rows = potentialSplits.map((s: any, i: number) => {
    return {
      id: i,
      icon: "/images/avax.svg",
      splitType: s.symbol === "USD" ? "Cash" : "Token",
      symbol: s.symbol,
      projectName: s.projectName,
      contractName: s.contractName.replace(/ - /g, "-"),
      value: s.value,
      quantity: s.quantity,
      type: capitalize(s.commissionType),
      source: s.roleName || s.poolName || s.modelName,
      model: s.modelName,
    };
  });

  const DataGridComponent = StyledGrid;

  return (
    <StyledBox>
      <DataGridComponent
        columns={cols}
        rows={rows}
        disableSelectionOnClick
        rowThreshold={0}
        initialState={{
          pinnedColumns: { left: ["icon", "projectName"] },
          sorting: { sortModel: [{ field: "projectName", sort: "asc" }] },
        }}
        pagination={false}
        autoPageSize={false}
        pageSize={undefined}
        components={{
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No contracts yet!
            </Stack>
          ),
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No contracts yet!
            </Stack>
          ),
        }}
      />
    </StyledBox>
  );
}

const cols: GridColumns = [
  { field: "projectName", headerName: "Project", width: 170 },
  { field: "contractName", headerName: "Contract", width: 360 },
  { field: "splitType", headerName: "Fee Type", width: 120 },
  { field: "symbol", headerName: "Currency", width: 120 },
  {
    field: "quantity",
    headerName: "Allocation",
    width: 150,
    valueFormatter: ({ value }) => number(value, 3),
  },
  {
    field: "type",
    headerName: "Type",
    width: 100,
    renderCell: ({ value }) => (
      <Chip
        variant="outlined"
        size="small"
        label={value}
        sx={{ color: "white" }}
        key={getKey()}
      />
    ),
  },
  {
    field: "source",
    headerName: "Source",
    width: 155,
    renderCell: ({ value }) => (
      <Chip
        variant="outlined"
        size="small"
        color="primary"
        label={value}
        key={getKey()}
      />
    ),
  },
  { field: "model", headerName: "Model", width: 180 },
];

import { useEffect, useLayoutEffect, useRef } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { AxisRenderer } from '@amcharts/amcharts5/.internal/charts/xy/axes/AxisRenderer'
import { Box } from '@mui/material'
import { useTheme, useMediaQuery } from '@mui/material'

/**
 * @summary Renders the portfolio summary line chart visible on the portfolio page.
 *
 * @param props
 * @returns {JSX.Element}
 */
function LineChart({ data }: Props) {
  const rootRef = useRef<am5.Root | null>(null)
  const seriesRef = useRef<am5xy.LineSeries | null>(null)
  const chartRef = useRef<am5xy.XYChart | null>(null)
  const xAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null)
  const yAxisRef = useRef<am5xy.ValueAxis<AxisRenderer> | null>(null)

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'))

  useLayoutEffect(() => {
    if (!rootRef.current) {
      rootRef.current = am5.Root.new('linediv')

      rootRef.current.numberFormatter.setAll({
        numberFormat: '$#,###.##a',
        bigNumberPrefixes: [
          { number: 1e6, suffix: 'M' },
          { number: 1e9, suffix: 'B' },
        ],
        smallNumberPrefixes: [],
      })

      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      const myTheme = am5themes_Animated.new(rootRef.current)
      myTheme.rule('Label').setAll({
        fontFamily: '"Roboto", monospace',
        fill: am5.color('#fff'),
        opacity: 0.8,
      })
      myTheme.rule('Grid').setAll({
        stroke: am5.color('#fff'),
        fill: am5.color('#fff'),
      })
      myTheme.rule('AxisRendererX').setAll({
        minGridDistance: 70,
      })
      myTheme.rule('AxisRendererY').setAll({
        minGridDistance: 50,
      })
      rootRef.current.setThemes([am5themes_Animated.new(rootRef.current), myTheme])

      chartRef.current = rootRef.current.container.children.push(
        am5xy.XYChart.new(rootRef.current, {
          panX: false,
          panY: false,
          pinchZoomX: false,
        }),
      )

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      var cursor = chartRef.current.set(
        'cursor',
        am5xy.XYCursor.new(rootRef.current, {
          behavior: 'none',
        }),
      )
      cursor.lineY.set('visible', false)

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      xAxisRef.current = chartRef.current.xAxes.push(
        am5xy.DateAxis.new(rootRef.current, {
          maxDeviation: 0.2,
          baseInterval: {
            timeUnit: 'day',
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(rootRef.current, {}),
          tooltip: am5.Tooltip.new(rootRef.current, { scale: 0.8 }),
        }),
      )

      yAxisRef.current = chartRef.current.yAxes.push(
        am5xy.ValueAxis.new(rootRef.current, {
          renderer: am5xy.AxisRendererY.new(rootRef.current, {}),
        }),
      )

      let xRenderer = xAxisRef.current.get('renderer')
      xRenderer.labels.template.setAll({
        fontSize: 15,
      })
      xRenderer.setAll({ fill: am5.color('rgb(72, 255, 207)') })

      let yRenderer = yAxisRef.current.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 15,
      })
      yRenderer.setAll({ fill: am5.color('rgb(72, 255, 207)') })

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      seriesRef.current = chartRef.current.series.push(
        am5xy.LineSeries.new(rootRef.current, {
          name: 'Series',
          xAxis: xAxisRef.current,
          yAxis: yAxisRef.current,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(rootRef.current, {
            labelText: '{valueY}',
          }),
          stroke: am5.color('rgb(72, 255, 207)'),
          fill: am5.color('rgb(0, 0, 0)'),
        }),
      )

      seriesRef.current.data.setAll(
        data.map((d: any) => ({ date: d.date.getTime(), value: d.value })),
      )

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      seriesRef.current.appear(1000)
      chartRef.current.appear(0, 0)
    }
  }, [])

  useEffect(() => {
    if (isSmall && xAxisRef.current && yAxisRef.current) {
      let xRenderer = xAxisRef.current.get('renderer')
      xRenderer.labels.template.setAll({
        fontSize: 11,
      })
      xRenderer.setAll({ fill: am5.color('rgb(72, 255, 207)') })

      let yRenderer = yAxisRef.current.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 11,
      })
    } else if (!isSmall && xAxisRef.current && yAxisRef.current) {
      let xRenderer = xAxisRef.current.get('renderer')
      xRenderer.labels.template.setAll({
        fontSize: 15,
      })
      xRenderer.setAll({ fill: am5.color('rgb(72, 255, 207)') })

      let yRenderer = yAxisRef.current.get('renderer')
      yRenderer.labels.template.setAll({
        fontSize: 15,
      })
    }
  }, [isSmall])

  return (
    <Box
      sx={{
        pb: 7,
        ml: -2,
        mr: -4,
        mt: 0.5,
        width: '100%',
        height: '100%',
      }}
    >
      <div
        id='linediv'
        style={{
          width: '100%',
          height: '100%',
          minHeight: isSmall ? 200 : isMedium ? 220 : 320,
        }}
      ></div>
    </Box>
  )
}

interface Props {
  data: any
}

export default LineChart

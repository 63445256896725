import { Card } from "@mui/material";
import { ContainerProps } from "../types";

const CardContainer = ({ children }: ContainerProps) => (
  <Card elevation={2} sx={{ width: "100%", height: "100%", p: 1.5 }}>
    {children}
  </Card>
);

export default CardContainer;

import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
} from "firebase/auth";
import { NotificationContextProps } from "../context/NotificationContext";

const firebaseConfig = {
  apiKey: "AIzaSyDD-pjiC6J5Oxqf5L32q3fBa0FhVvxe2Bo",
  authDomain: "treasury-monitoring.firebaseapp.com",
  projectId: "treasury-monitoring",
  storageBucket: "treasury-monitoring.appspot.com",
  messagingSenderId: "805730267241",
  appId: "1:805730267241:web:e7120518485d5d3f24f2f8",
  measurementId: "G-E2XG8QJM78",
};

const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

const login = (handleLoading: any, notifications: NotificationContextProps) => {
  handleLoading(true);
  signInWithPopup(auth, provider)
    .then((result) => {
      handleLoading(false);
      const user = result.user;
      const acceptedDomains = ["republic.co", "republiccrypto.com"];
      if (!user.email || !acceptedDomains.find(d => d === user.email?.split("@")[1])) {
        logout();
        notifications.set({
          message: `Must be a ${acceptedDomains.join(' or ')} address. Not: ${user.email?.split("@")[1]}`,
          type: "error",
        });
      }
    })
    .catch((error) => {
      handleLoading(false);
      const errorMessage = error.message;
      notifications.set({
        message: errorMessage,
        type: "error",
      });
    });
};

const logout = () => signOut(auth);

export { app, auth, login, logout };

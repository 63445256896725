import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";

const Info = ({ text }: { text: string }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  return (
    <Tooltip
      title={text}
      open={showTooltip}
      onOpen={() => setShowTooltip(true)}
      onClose={() => setShowTooltip(false)}
      componentsProps={{
        tooltip: {
          style: {
            backgroundColor: "#0e0e0e",
            outline: "1px solid rgba(255,255,255,0.8)",
            borderRadius: 0,
            fontFamily: "'Roboto', monospace",
            fontSize: 12,
            marginTop: 20,
            opacity: 0.9,
          },
        },
      }}
    >
      <IconButton
        aria-label="Info"
        size="small"
        sx={{ p: 0, lineHeight: 1 }}
        onClick={() => setShowTooltip(true)}
      >
        <InfoIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};

export default Info;

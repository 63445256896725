// @ts-nocheck
import { useEffect, useRef } from "react";
import { Tooltip, Typography } from "@mui/material";
import SvgGauge, { GaugeOptions, GaugeInstance } from "svg-gauge";
import { currency } from "../../utils/format";

const Gauge = ({ value, data, symbol, position }: Props) => {
  const gaugeEl = useRef<HTMLDivElement>(null);
  const gaugeRef = useRef<GaugeInstance | null>(null);

  useEffect(() => {
    if (!gaugeRef.current) {
      if (!gaugeEl.current) return;
      const options: GaugeOptions = {
        color: (value) => "rgb(72, 255, 207)",
        dialRadius: 48,
        label: (value) => ``,
        gaugeClass: ".gauge-class",
      };
      gaugeRef.current = SvgGauge(gaugeEl.current, options);
      gaugeRef.current?.setValue(1);
    }
    gaugeRef.current?.setValueAnimated(value, 2);
  }, [value]);

  const tooltipText = (
    <>
      <div>
        <span>
          {value !== 0 && data && data.high && data.last
            ? `${symbol.toUpperCase()} is ${(
                100 -
                (data.last / data.high) * 100
              ).toFixed(1)}% from its highest closing
        price in the last month.`
            : "Not enough data available."}
        </span>
      </div>

      {data && data.high && data.low && (
        <div style={{ paddingTop: 5 }}>
          <span style={{ paddingTop: 4 }}>
            High: {`${currency(data.high, 8)}`}
          </span>
          <br />
          <span>Low: &nbsp;{`${currency(data.low, 8)}`}</span>
          <br />
          <span>Last: {`${currency(data.last, 8)}`}</span>
        </div>
      )}
    </>
  );

  return (
    <Tooltip
      title={tooltipText}
      componentsProps={{
        tooltip: {
          style: {
            backgroundColor: "#0e0e0e",
            outline: "1px solid rgba(255,255,255,0.8)",
            borderRadius: 0,
            fontFamily: "'Roboto', monospace",
            fontSize: 12,
            marginTop: 20,
          },
        },
      }}
      TransitionProps={{ timeout: 0 }}
      followCursor
    >
      <div
        style={{
          width: 70,
          height: 70,
          position: "relative",
        }}
      >
        <div ref={gaugeEl} />
        {symbol && (
          <img
            src={`/images/${symbol}.svg`}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: 40,
              opacity: 0.92,
              fill: "black!important",
            }}
          />
        )}
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            opacity: 0.95,
            fill: "black!important",
            marginTop: 26,
          }}
        >
          <Typography
            sx={{
              fontFamily: "'Roboto', monospace",
              fontSize: 12,
              opacity: 0.9,
            }}
          >
            {symbol.toUpperCase()}
          </Typography>
        </div>

        <div
          style={{
            position: "absolute",
            top: "-19px",
            left: "-14px",
            transform: "translate(-50%, -50%)",
            opacity: 0.3,
            fill: "black!important",
            marginTop: 26,
          }}
        >
          <Typography sx={{ fontFamily: "'Roboto', monospace", fontSize: 14 }}>
            #{position}
          </Typography>
        </div>
      </div>
    </Tooltip>
  );
};

interface Props {
  value?: number;
  data?: { [key: string]: any };
  symbol?: string;
  position: number;
}

export default Gauge;

import { Typography, Box, Divider } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { currency } from "../../utils/format";

const StatsTable = ({ total, cash, tokens, change, days }: Props) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={{ ...bodyTextLeft, fontSize: 16 }}>
          {isSmall ? "Total" : "Potential Commission"}
        </Typography>
        <Typography sx={{ ...bodyTextRight, fontSize: 16, fontWeight: 700 }}>
          {currency(total)}
        </Typography>
      </Box>
      <Divider sx={{ mt: 0.5, mb: 0.25 }} />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={bodyTextLeft}>Total Cash</Typography>
        <Typography sx={bodyTextRight}>{currency(cash)}</Typography>
      </Box>
      <LineBreak />

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={bodyTextLeft}>Total Tokens</Typography>
        <Typography sx={bodyTextRight}>{currency(tokens)}</Typography>
      </Box>
      <LineBreak />

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Typography sx={bodyTextLeft}>1m Change</Typography>
        <Typography sx={bodyTextRight}>{currency(change)}</Typography>
      </Box>
    </>
  );
};

const bodyTextLeft = {
  fontFamily: '"Roboto", monospace',
  fontSize: 14,
  opacity: 0.8,
};

const bodyTextRight = { ...bodyTextLeft, ml: "auto" };

const LineBreak = () => <Divider sx={{ mt: 0.25, mb: 0.25 }} />;

interface Props {
  total: number;
  cash: number;
  tokens: number;
  change: number;
  days: number;
}

export default StatsTable;

import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={116}
    height={36}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"Republic Crypto Logo"}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101.283 11.607V16h-3.048v3.67h3.048v4.384h-3.048v.008h-4.442v-8.063h-1.51v-4.392h1.51v-3.07h4.442v3.07h3.048Zm.733 6.239c0-3.546 2.946-6.516 7.015-6.516 4.045 0 6.969 2.923 6.969 6.516 0 3.546-2.947 6.515-7.016 6.515-4.044 0-6.968-2.925-6.968-6.515Zm9.659 0c0-1.52-1.122-2.809-2.69-2.809-1.612 0-2.641 1.266-2.641 2.809 0 1.52 1.122 2.808 2.689 2.808 1.613 0 2.642-1.265 2.642-2.808Zm-29.799-6.238h-4.574v16.138h4.574v-4.838c.842.945 1.997 1.432 3.971 1.432 3.08 0 5.752-2.463 5.752-6.516 0-4.029-2.671-6.492-5.776-6.492-1.95 0-3.056.534-3.947 1.57v-1.294Zm2.553 3.383c1.493 0 2.671 1.174 2.671 2.808 0 1.728-1.18 2.878-2.67 2.878-1.47 0-2.649-1.174-2.649-2.831 0-1.681 1.18-2.855 2.648-2.855Zm-43.644 2.854c0-3.522 2.782-6.492 6.782-6.492 2.596 0 4.325 1.105 5.402 2.785l-2.971 2.12c-.607-.829-1.333-1.22-2.291-1.22-1.613 0-2.596 1.243-2.596 2.807 0 1.611 1.029 2.832 2.525 2.832 1.053 0 1.683-.506 2.385-1.312l3.017 2.141c-1.169 1.704-2.853 2.855-5.636 2.855-3.812 0-6.617-2.946-6.617-6.516Zm22.102-1.853h-4.123v8.07H54.32V11.607h6.949l1.618 4.385Zm7.873 11.684h-4.507L67.941 23l-4.688-11.582h4.507l2.11 6.232 2.168-6.007h4.506L70.76 27.676Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.926 0H0v35.75h32.189l-9.776-15.06a10.94 10.94 0 0 0 1.676-.994c2.74-1.974 4.523-5.18 4.523-8.8 0-5.533-4.164-10.097-9.548-10.773C18.374.033 17.232 0 16.926 0Zm-.32 16.019 2.498 3.842h-2.499v-3.842Zm-1.955-1.645v5.487h-3.583l3.584-5.487Zm0 7.473h-4.88l-7.817 11.97h12.697v-11.97Zm1.954 11.97H28.18l-7.784-11.97h-3.79v11.97Zm-2.702-21.953-5.16 7.997h-6.79V1.932h5.492l6.458 9.931Zm-6.441 9.983H1.954v8.537l5.508-8.537Zm14.1-2.897-.503.24L9.836 1.934h7.09c.273 0 1.318.032 1.884.106h.008c4.414.556 7.84 4.303 7.84 8.857 0 3.548-2.08 6.613-5.095 8.054Z"
      fill="#48FFCF"
    />
  </svg>
);

export default SvgComponent;

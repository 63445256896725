import { SVGProps } from "react";

const CryptoTextLogo = (props: CryptoTextProps) => (
  <svg
    viewBox="0 0 76 20"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <path
      d="M101.283 11.607V16h-3.048v3.67h3.048v4.384h-3.048v.008h-4.442v-8.063h-1.51v-4.392h1.51v-3.07h4.442v3.07h3.048Zm.733 6.239c0-3.546 2.946-6.516 7.015-6.516 4.045 0 6.969 2.923 6.969 6.516 0 3.546-2.947 6.515-7.016 6.515-4.044 0-6.968-2.925-6.968-6.515Zm9.659 0c0-1.52-1.122-2.809-2.69-2.809-1.612 0-2.641 1.266-2.641 2.809 0 1.52 1.122 2.808 2.689 2.808 1.613 0 2.642-1.265 2.642-2.808Zm-29.799-6.238h-4.574v16.138h4.574v-4.838c.842.945 1.997 1.432 3.971 1.432 3.08 0 5.752-2.463 5.752-6.516 0-4.029-2.671-6.492-5.776-6.492-1.95 0-3.056.534-3.947 1.57v-1.294Zm2.553 3.383c1.493 0 2.671 1.174 2.671 2.808 0 1.728-1.18 2.878-2.67 2.878-1.47 0-2.649-1.174-2.649-2.831 0-1.681 1.18-2.855 2.648-2.855Zm-43.644 2.854c0-3.522 2.782-6.492 6.782-6.492 2.596 0 4.325 1.105 5.402 2.785l-2.971 2.12c-.607-.829-1.333-1.22-2.291-1.22-1.613 0-2.596 1.243-2.596 2.807 0 1.611 1.029 2.832 2.525 2.832 1.053 0 1.683-.506 2.385-1.312l3.017 2.141c-1.169 1.704-2.853 2.855-5.636 2.855-3.812 0-6.617-2.946-6.617-6.516Zm22.102-1.853h-4.123v8.07H54.32V11.607h6.949l1.618 4.385Zm7.873 11.684h-4.507L67.941 23l-4.688-11.582h4.507l2.11 6.232 2.168-6.007h4.506L70.76 27.676Z"
      style={{
        fill: props.fill,
      }}
      transform="translate(-40.785 -8.537)"
    />
  </svg>
);

interface CryptoTextProps extends SVGProps<SVGSVGElement> {
  fill: string;
}

export default CryptoTextLogo;

import { createContext, useState, useEffect } from "react";
import { getAuth, getIdToken, User } from "firebase/auth";
import { app } from "../firebase";

const initialState = {
  user: null,
  getToken: () => {},
};

export const AuthProvider = createContext<AuthContextProps>(initialState);

export const AuthContext = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const [values, setValues] = useState<AuthContextProps>(initialState);

  const auth = getAuth(app);

  /* Update context on auth state change */
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user && user !== null) {
        setValues({
          user,
          getToken: () => getIdToken(user),
        });
      } else {
        setValues({
          user: null,
          getToken: () => {},
        });
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  return (
    <AuthProvider.Provider value={values}>{children}</AuthProvider.Provider>
  );
};

interface AuthContextProps {
  user: User | null;
  getToken: () => any;
}

const processPotentialSplits = (splits: any[], symbolSnapshot: any[], marketData: any[]) => {
  if (!splits || splits.length === 0) return []
  let totalCashValue = 0
  let totalTokenValue = 0
  let totalUntrackedTokens = 0
  let totalFromPools = 0
  let totalFromRoles = 0
  let totalFromIndividual = 0
  let totalFromOverride = 0
  const poolBreakDown: { [key: string]: number } = {}

  const availablePrices = marketData.map(d => Object.keys(d)[0])
  const roles: string[] = []

  /* Add market data to splits where available */
  const cashSplits = splits.filter(s => s.symbol === 'USD')
  cashSplits.forEach(s => {
    /* Skim the total and how much is earned from each commission type */
    switch (s.commissionType) {
      case 'pool':
        totalFromPools += s.value
        if (poolBreakDown[s.poolName]) {
          poolBreakDown[s.poolName] += s.value
        } else {
          poolBreakDown[s.poolName] = s.value
        }
        break
      case 'role':
        if (!roles.includes(s.roleName)) roles.push(s.roleName)
        totalFromRoles += s.value
        break
      case 'individual':
        totalFromIndividual += s.value
        break
      case 'override':
        totalFromOverride += s.value
        break
    }
    totalCashValue += s.value
  })

  /* Add market data to splits where available */
  const priceMap: any = {}
  let totalEquityCurve: any = []
  const tokenSplits = splits
    .filter((s) => s.symbol !== "USD" && s.quantity > 0.00001 && s.value) // Hack to not show remainder after full liquidation
    .map((s) => {
      const data = symbolSnapshot.find((d) => d.Id === s.symbol) || false;
      const split: any = {
        ...s,
        ...data,
        merged: data ? true : false,
      }

      /* Skip if there is no market date for this coin */
      const historyIndex = availablePrices.indexOf(s.symbol)
      if (
        historyIndex !== -1 &&
        marketData[historyIndex][s.symbol][0].Open !== 0 &&
        marketData[historyIndex][s.symbol][marketData[historyIndex][s.symbol].length - 1].Close !==
          0
      ) {
        /* Flag so we know this split has all the data */
        split.complete = true

        /* Sort market data by date */
        const marketTimeline = marketData[historyIndex][s.symbol].sort(
          function (a: any, b: any) {
            return (
              new Date(a.Timestamp).getTime() - new Date(b.Timestamp).getTime()
            );
          }
        );

        if(s.symbol === "STATE") alert("what?")

        /* Grab high/low values and calculate change and current position  */
        let [open, last, high, low, volume, volHigh, volLow]: any[] = [
          marketData[historyIndex][s.symbol][0].Open,
          marketData[historyIndex][s.symbol][marketData[historyIndex][s.symbol].length - 1].Close,
          0,
          0,
          marketData[historyIndex][s.symbol][marketData[historyIndex][s.symbol].length - 1].Volume,
          0,
          0,
        ]

        /* Grad the percent change since the start of the dataset */
        let change = (100 - (100 * last) / open) * -1

        const eC = marketTimeline.map((p: any, i: number) => {
          if (p.Close > high) high = p.Close
          if (p.Close < low || i === 0) low = p.Close
          if (p.Volume > volHigh) volLow = p.Volume
          if (p.Volume < volLow || i === 0) volHigh = p.Volume
          return {
            date: new Date(p.Timestamp),
            value:
              s.quantity *
              (i === marketTimeline.length - 1 && split.merged && split.Quote
                ? split.Quote
                : p.Close),
            volume: p.Volume,
          }
        })

        /* The data that will be available for painting in the UI */
        priceMap[
          `${s.contractName} - ${s.symbol} - ${s.roleName || s.poolName || s.modelName}`
          // s.symbol
        ] = {
          equityCurve: eC,
          team:
            s.contractName
              ?.replace(/ /g, '')
              .split('-')[1]
              ?.replace(/([A-Z])/g, ' $1')
              .trim() ?? s.modelName.split('-')[0],
          symbol: s.symbol,
          open,
          last,
          high,
          low,
          change,
          power: ((last - low) / (high - low)) * 100 ?? 0,
          volHigh,
          volLow,
          volume,
          fullData: true,
        }
      } else {
        /* Mark it as not having complete data */
        split.complete = false
      }

      /* Group the total values from each split */
      const value = split.value && split.value !== null ? Number(split.value) : 0

      switch (split.commissionType) {
        case 'pool':
          totalFromPools += value
          if (poolBreakDown[split.poolName]) {
            poolBreakDown[split.poolName] += value
          } else {
            poolBreakDown[split.poolName] = value
          }
          break
        case 'role':
          totalFromRoles += value
          break
        case 'individual':
          totalFromIndividual += value
          break
        case 'override':
          totalFromOverride += value
          break
      }
      totalTokenValue += value

      /* If the token has a snapshot but no times series use the latest price */
      if (split.merged && !split.complete && split.Quote && !Number.isNaN(split.Quote)) {
        const tokenValue = split.Quote * split.quantity
        totalUntrackedTokens += tokenValue
      }

      /* If no snapshot and no time series use the value from sonar */
      if (!split.merged || !split.Quote || Number.isNaN(split.Quote)) {
        totalUntrackedTokens += value
      }

      return split
    })

  /* Generate the equity curve for the combined portfolio */
  Object.keys(priceMap).forEach((key: any, i) => {
    if (priceMap[key].fullData) {
      if (i === 0)
        totalEquityCurve = [
          ...priceMap[key].equityCurve.map((e: any) => {
            // if (Number.isNaN(e.value)) {
            //   console.log(key)
            //   console.log(e.value)
            //   console.log(e.timestamp)
            //   console.log(e.value)
            //   console.log(totalCashValue)
            //   console.log(totalUntrackedTokens)
            // }
            // if (!Number.isNaN(e.value)) {
            //   console.log(key)
            //   console.log(e.value)
            //   console.log(e.timestamp)
            //   console.log(e.value)
            //   console.log(totalCashValue)
            //   console.log(totalUntrackedTokens)
            // }
            return {
              ...e,
              value: e.value + totalCashValue + totalUntrackedTokens,
            }
          }),
        ]
      else {
        totalEquityCurve = totalEquityCurve.map((day: any, i: number) => {
          // if (Number.isNaN(day.value)) {
          //   console.log(key)
          //   console.log(day.value)
          //   console.log(day)
          // }
          // if (!!!Number.isNaN(day.value)) {
          //   console.log(key)
          //   console.log(day.value)
          //   console.log(day)
          // }
          return {
            ...day,
            value: day.value + (priceMap[key].equityCurve[i]?.value ?? 0),
            volume: day.volume + (priceMap[key].equityCurve[i]?.volume ?? 0),
          }
        })
      }
    }
  })

  const tokensByPerformance: any[] = Object.values(priceMap)
    .sort((a: any, b: any) => (a.power > b.power ? -1 : 1))
    .filter(
      (value: any, index: number, self) =>
        index === self.findIndex((t: any) => t.symbol === value.symbol),
    )

  /* Calculate the average position relative to the month for each team */
  const teamPower: any = {}
  Object.keys(priceMap).forEach(key => {
    if (!teamPower[priceMap[key].team]) {
      teamPower[priceMap[key].team] = []
    }
    teamPower[priceMap[key].team].push(priceMap[key].power)
  })

  /* Calculate the values for the volume chart */
  const totalVolume1m = totalEquityCurve.map((d: any) => ({
    date: d.date,
    volume: d.volume,
  }))

  /* Calculate the current, high and low volume values */
  const totalCurrentVolume = totalVolume1m[totalVolume1m.length - 1]?.volume ?? 0
  const totalVolumeHigh =
    totalVolume1m.length > 0 ? Math.max(...totalVolume1m.map((v: any) => v.volume)) : 0
  const totalVolumeLow =
    totalVolume1m.length > 0 ? Math.min(...totalVolume1m.map((v: any) => v.volume)) : 0

  /* Calculate the current, high and low dollar value */
  const totalValueCurrent = totalEquityCurve[totalEquityCurve.length - 1]?.value ?? 0
  const totalValueHigh =
    totalEquityCurve.length > 0 ? Math.max(...totalEquityCurve.map((v: any) => v.value)) : 0
  const totalValueLow =
    totalEquityCurve.length > 0 ? Math.min(...totalEquityCurve.map((v: any) => v.value)) : 0

  /* Calculate the combined change during the given range */
  const change =
    totalEquityCurve.length > 0
      ? totalEquityCurve[totalEquityCurve.length - 1].value - totalEquityCurve[0].value
      : 0

  /* Count the days we have data for */
  const totalDays = totalEquityCurve.length

  /* Make it all available to the children */
  return {
    totalCashValue,
    totalTokenValue,
    totalValue: totalCashValue + totalTokenValue,
    change,
    cashSplits,
    tokenSplits,
    numCashSplits: cashSplits.length,
    numTokenSplits: tokenSplits.length,
    totalFromPools,
    totalFromRoles,
    totalFromIndividual,
    totalFromOverride,
    poolBreakDown,
    priceMap,
    totalEquityCurve,
    totalValueCurrent,
    totalValueHigh,
    totalValueLow,
    totalVolume1m,
    totalCurrentVolume,
    totalVolumeHigh,
    totalVolumeLow,
    totalDays,
    teamPower,
    tokensByPerformance,
    roles,
  }
}

export default processPotentialSplits

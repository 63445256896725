import { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { AuthProvider, DataProvider } from "../../context";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Title from "../../components/common/Title";
import Info from "../../components/common/Info";
import ContractList from "../../components/tables/ContractList";
import CommissionList from "../../components/tables/CommissionList";
import { Chip, Divider, Typography } from "@mui/material";
import { CardContainer } from "../../components/common";
import { NotificationProvider } from "../../context";

const cardStyle = { width: "100%", p: 1.5 };

let keyCount = 0;
const getKey = () => {
  const key = keyCount;
  keyCount++;
  return key;
};

const Settings = () => {
  const { loading, data } = useContext(DataProvider);
  const auth = useContext(AuthProvider);
  const { user } = auth;
  const notification = useContext(NotificationProvider);

  if (loading) return null;
  return (
    <Grid container spacing={2}>
      {/* Top Row */}
      <Grid item xs={12} md={4}>
        <CardContainer>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ ml: "auto", lineHeight: 1 }}>
              <Info text="Contact your team leader if there are any issues with your data." />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center", pt: 1, pb: 1.5 }}>
            <img
              src={user?.photoURL ?? ""}
              style={{ height: 100, border: "none" }}
              className="circle"
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ pb: 1.5, pl: 1.5, pr: 1.5, pt: 0 }}>
              <Divider
                sx={{ mt: 2, mb: 1, borderColor: "rgba(81, 81, 81, 1)" }}
              />
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  Name
                </Typography>
                <Typography
                  sx={{
                    ml: "auto",
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                    fontWeight: 700,
                  }}
                >
                  {data.name}
                </Typography>
              </Box>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: "rgba(81, 81, 81, 1)" }}
              />
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    ml: "auto",
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                    fontWeight: 500,
                  }}
                >
                  {data.email}
                </Typography>
              </Box>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: "rgba(81, 81, 81, 1)" }}
              />

              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  Start Date
                </Typography>
                <Typography
                  sx={{
                    ml: "auto",
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  {data.startDate.split("T")[0]}
                </Typography>
              </Box>

              <Divider
                sx={{ mt: 1, mb: 1, borderColor: "rgba(81, 81, 81, 1)" }}
              />
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  Pools
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    rowGap: 1,
                  }}
                >
                  {Object.keys(data.potential?.poolBreakDown ?? []).length >
                  0 ? (
                    Object.keys(data.potential.poolBreakDown).map((key) => (
                      <Chip
                        label={key}
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 0.5 }}
                        key={getKey()}
                      />
                    ))
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: '"Roboto", monospace',
                        fontSize: 15,
                        opacity: 0.8,
                      }}
                    >
                      None
                    </Typography>
                  )}
                </Box>
              </Box>

              <Divider
                sx={{ mt: 1, mb: 1, borderColor: "rgba(81, 81, 81, 1)" }}
              />
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography
                  sx={{
                    fontFamily: '"Roboto", monospace',
                    fontSize: 15,
                    opacity: 0.8,
                  }}
                >
                  Roles
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    rowGap: 1,
                  }}
                >
                  {data.potential?.roles && data.potential.roles.length > 0 ? (
                    data.potential.roles.map((key: string) => (
                      <Chip
                        label={key}
                        variant="outlined"
                        color="primary"
                        size="small"
                        sx={{ ml: 0.5 }}
                        key={getKey()}
                      />
                    ))
                  ) : (
                    <Typography
                      sx={{
                        fontFamily: '"Roboto", monospace',
                        fontSize: 15,
                        opacity: 0.8,
                      }}
                    >
                      None
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContainer>
      </Grid>

      <Grid item xs={12} md={8}>
        <Card elevation={2} sx={{ ...cardStyle }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Title prefix={"S1"} title={"COMMISSIONS"} />
            <Box sx={{ ml: "auto", lineHeight: 1 }}>
              <Info text="Commissions for pools are distributed evenly between all pool members" />
            </Box>
          </Box>
          <Box sx={{ mr: -1.5, ml: -1.5, mb: -1.5, mt: 1.5 }}>
            <CommissionList />
          </Box>
        </Card>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card elevation={2} sx={{ ...cardStyle }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Title prefix={"S2"} title={"CONTRACTS"} />
            <Box sx={{ ml: "auto", lineHeight: 1 }}>
              <Info text="These values are likely to change and amounts are not finalized until payments are received and/or tokens are liquidated." />
            </Box>
          </Box>
          <Box sx={{ mr: -1.5, ml: -1.5, mb: -1.5, mt: 1.5 }}>
            <ContractList />
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Settings;
